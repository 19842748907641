import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import moment from "moment";
import "moment/dist/locale/ru";
import { defineCustomElements } from '@ionic/pwa-elements/loader';

import Ripple from './directives/ripple/ripple'
import RippleCircle from '@/directives/ripple-circle/script'
import PullToRefresh from './directives/pull-to-refresh'
import VueLazyload from 'vue-lazyload'

import { Capacitor } from '@capacitor/core';
import { SafeArea } from 'capacitor-plugin-safe-area';


import storage from '@/plugins/storage'
import capacitor from '@/plugins/capacitor'
import telegramApp from '@/plugins/telegram.app'
import migration from '@/plugins/capacitor/migration'
import updater from '@/plugins/updater'
import errorListiner from './plugins/error.listiner'
import metrika from './plugins/yandex.metrika'
import vkBridge from '@vkontakte/vk-bridge';

import 'bootstrap/dist/css/bootstrap-grid.min.css'
import 'bootstrap/dist/css/bootstrap-reboot.min.css'


import '@/assets/fonts/icomoon/style.css'
import '@/assets/fonts/montserrat/style.css'
import '@/assets/themes/light.scss'
import '@/assets/styles/default.scss'
import '@/assets/styles/forms.scss'
import '@/assets/styles/buttons.scss'
import '@/assets/styles/typography.scss'
import telegram from './plugins/telegram.app';

moment.locale('ru');

window.addEventListener('error', function (e) {
    errorListiner.onError(e);
})

window.addEventListener("unhandledrejection", function (e) {
    errorListiner.onError(e);
});

//асинхронная инициализация, чтобы можно было получить параметры из приложения синхронно
//до старта приложения
let init = async () => {

    //устанавливаем код платформы
    store.commit("setPlatform", Capacitor.getPlatform());

    //устанавливаем код платформы запуска (vk и прочее)
    let urlParams = new URLSearchParams(window.location.search);
    store.commit("setLaunchPlatform", urlParams.get('launch'));

    //если работаем внтури ВК
    if(store.getters.getLaunchPlatform == "vk") {
        //переход на использование хранения настроек в VK
        await storage.migrateToVK();
    }

    //если плагин миграции доступен
    if(Capacitor.isPluginAvailable('Migration') && Capacitor.isNativePlatform) {
        //он там сам проверяет была ли миграция произведена ранее
        await migration.migrate();
        //загружаем данные о нативном приложении
        store.dispatch("loadAppInfo");
    }

    //восстанавливаем список подключений
    let arConnections = await storage.get("connections");
    if (arConnections !== null) {
        console.log(arConnections);
        store.commit("setConnections", arConnections);
    }

    //восстанавливаем текущее подключение
    let key = await storage.get("current_connection");
    if ( key !== null && key) {

        let isTest = import.meta.env.VITE_DEFAULT_CONNECTION.includes("test");
        
        //если не тестовая среда, но текущее подключение почему-то к тестовому серверу
        if(!isTest && key.includes("test")) {
            key = "";
        }

        //сохраням в текущем состоянии
        store.commit("setCurrentConnection", key);
    }

    //восстанавливаем токен Push
    let pushToken = await storage.get("push_token");
    if (pushToken !== null) {
        store.commit("setPushToken", pushToken);
    }

    //если плагин безопасной зоны экрана доступен
    if(Capacitor.isPluginAvailable('SafeArea')) {
        //получаем безопасные границы
        let response = await SafeArea.getSafeAreaInsets();
        store.commit("setSafeArea", response.insets);
    }

    //получаем флаг принятия правил использования
    let termsOfUseAccepted = await storage.get("terms_of_use_accepted");
    if(termsOfUseAccepted == "Y") {
        store.commit("setTermsOfUseAccepted", true);
    }

    const app = createApp(App)

    //плагин ряби на кнопках
    Ripple.color = 'rgba(100, 100, 100, 0.15)';
    app.directive('ripple', Ripple);
    app.directive('ripple-circle', RippleCircle);

    //плагин обновления экрана при оттягивании
    app.directive('pull-to-refresh', PullToRefresh);

    //отложенная загрузка картинок
    app.use(VueLazyload, {
        throttleWait: 115,
        attempt: 3,
        silent: false,
        preLoad:2,
        observer: true,
        observerOptions: {
            rootMargin: '150px',
            threshold: 0.01
        }
    })

    app.use(store)
    app.use(router)

    //Подключение capacitor (до монтирования app, чтобы обработчики событий в capacitor.js успели встать)
    capacitor.init();

    //Подключение telegram (до монтирования app, чтобы обработчики событий в telegram.js успели встать)
    telegramApp.init();
    
    app.mount('#app');

    //автообновление
    updater.init();
    //яндекс метрика
    metrika.init();
    //Мост с контейнером "ВК" - заявляем о готовности приложения
    vkBridge.send("VKWebAppInit");
};

init();

// Call the element loader after the platform has been bootstrapped
defineCustomElements(window);