import { LocalNotifications } from '@capacitor/local-notifications'

import bus from '@/plugins/bus'
import moment from "moment";
import googlePush from '@/plugins/capacitor/google.push'

const instance = {

    init() {
        //подписываемся на событие необходимости показа локального уведомления
        bus.on('OKRAINA_NOTIFICATION_E_SHOW', async (data) => {

            let status = await LocalNotifications.checkPermissions();
            if(status.display == 'prompt' || status.display == 'prompt-with-rationale') {
                status = await LocalNotifications.requestPermissions();
            }
            if (status.display !== 'granted') {
                return;
            }

            let arSchedule = {
                id: parseInt(moment().format("X")),
                title: data.TITLE,
                body: data.BODY,
                extra: data
            };
            if (data.image) {
                arSchedule["Attachments"] = [
                    {
                        id: data.image,
                        url: data.image
                    }
                ];
            }

            LocalNotifications.schedule({ notifications: [arSchedule] });

        });

        //обработка действия по уведомлению
        LocalNotifications.addListener('localNotificationActionPerformed', (event) => {
            googlePush.onPushClick(event.notification.extra);
        });
    }
}

export default instance;