import bus from '@/plugins/bus'
import speech from '@/plugins/capacitor/speech'
import yoomoney from '@/plugins/capacitor/yoomoney'
import sberpay from '@/plugins/capacitor/sberpay'
import barcodeScanner from '@/plugins/capacitor/barcode.scanner'
import camera from '@/plugins/capacitor/camera'

import googlePush from '@/plugins/capacitor/google.push'
import googleLocalNotifications from '@/plugins/capacitor/google.localnotifications'
import hmsPush from '@/plugins/capacitor/hms.push'
import hmsLocalNotifications from '@/plugins/capacitor/hms.localnotifications'

import googleGeo from '@/plugins/capacitor/google.geo'

import tools from '@/plugins/tools'
import store from '@/store'
import storage from '@/plugins/storage'
import router from '@/router'
import vkBridge from '@vkontakte/vk-bridge';

import { StatusBar, Style } from '@capacitor/status-bar';
import { NavigationBar } from '@hugotomazi/capacitor-navigation-bar';
import { Share } from '@capacitor/share';
import { Capacitor } from '@capacitor/core';
import { SplashScreen } from '@capacitor/splash-screen';
import { Keyboard } from '@capacitor/keyboard';
import { App } from '@capacitor/app';
import { Clipboard } from '@capacitor/clipboard';
import { Device } from '@capacitor/device';
import { CheckPlayServices } from '@timmedia/check-play-services';

export default {

    /**
     * Инициализирует плагины
     */
    init() {
        if (Capacitor.isNativePlatform()) {
            //инициализируем обработку события приложения
            this.processApp();
            //инициализируем работу со статус баром
            this.processStatusBar();
            if (Capacitor.getPlatform() === 'android') {
                //инициализируем работу с навигационным баром
                this.processNavigationBar();
            }
            //инициализируем распознование голоса
            speech.init();
            //SDK Юкасса
            this.processYoomoney();
            //SDK SberPay
            this.processSberPay();
            //экран загрузки
            this.processSplash();
            
            //события клавиатуры
            this.processKeybord();
            
            //производитель
            Device.getInfo().then((result) => {
                store.commit("setManufacturer",result.manufacturer.toLowerCase());
            });

            //сервисы GMS / HMS
            this.processMobileServices();
        }

        //инициализирцем плагин сканера штрих кодов
        barcodeScanner.init();

        //поделиться
        this.processShare();

        //камера
        camera.init();

        //если вебка 
        if (!Capacitor.isNativePlatform()) {
            //местоположение
            googleGeo.init();
        }

        //буфер обмена
        this.processClipboard();
    },

    /**
    * Статус бар - изменение цвет в зависимости от экрана
    */
    processStatusBar() {

        var onThemeChange = () => {
            let root = document.documentElement;
            var styles = window.getComputedStyle(root);
            var color = styles.getPropertyValue('--color-background');
            if (color) {
                color = color.trim();
            }

            
            if (root.dataset.theme == 'light') {
                StatusBar.setStyle({ style: Style.Light });
            } else {
                StatusBar.setStyle({ style: Style.Dark });
            }
            
            //только для Android
            if (Capacitor.getPlatform() === 'android') {
                StatusBar.setBackgroundColor({ color: tools.convertHexColor(color) });

                //устанавливаем overlay статус бара сразу в MainActivity.java при запуске приложения
                //иначе если устанавливать через плагин 
                //то видно как "прыгает" экран
                
                
                StatusBar.setOverlaysWebView({ overlay: true });
            }
            
        };

        //подписываемся на событие завершения смены темы
        bus.on('OKRAINA_APP_E_THEME_CHANGED', onThemeChange);

        //запускаем при инициалиазции
        onThemeChange();

        //скрыть статус бар
        bus.on('OKRAINA_OKRAINA_STATUS_BAR_E_HIDE', () => {
            StatusBar.hide()
        });

        //показать статус бар
        bus.on('OKRAINA_OKRAINA_STATUS_BAR_E_SHOW', () => {
            StatusBar.show()
        });


    },

    /**
     * Навигационный бар - изменение цвет в зависимости от экрана
     */
    processNavigationBar() {

        if(!Capacitor.isPluginAvailable('NavigationBar')) {
            return;
        }

        var onThemeChange = () => {
            let root = document.documentElement;
            var styles = window.getComputedStyle(root);
            var color = styles.getPropertyValue('--color-menu-background');
            if (color) {
                color = color.trim();
            }

            var isLight = root.dataset.theme == 'light';
            NavigationBar.setColor({ color: tools.convertHexColor(color), darkButtons: isLight });
        }
        //подписываемся на событие завершения смены темы
        bus.on('OKRAINA_APP_E_THEME_CHANGED', onThemeChange);

        bus.on('OKRAINA_NAVIGATION_BAR_E_SET_TRANSPARENT', (isTransparent) => {
            NavigationBar.setTransparency({isTransparent: isTransparent});
        });


        //запускаем при инициалиазции
        onThemeChange();
    },

    /**
     * Поделиться
     */
    processShare() {

        //работает только для натива
        if (!Capacitor.isNativePlatform()) {
            return;
        }

        //подписываемся на событие получения фото с камеры
        bus.on('OKRAINA_APP_E_SHARE', (params) => {
            Share.share(params);
        });
    },

    /**
     * SDK Юкасса
     */
    processYoomoney() {

        let apiDataByServers = {
            "test.dostavka.okraina.ru": {
                key: "test_ODkxNTI3a7IRCe0Lu_-oNpMrrHm5-sXOvJIK4CDXwsk",
                shopId: 891527
            },
            "dostavka.okraina.ru": {
                key: "live_MjI2ODg24tPrWJMZWdWVBQ5Ne2i99ot7M888vfe7lBM",
                shopId: 226886
            },
        };

        //запрос токена оплаты
        bus.on('OKRAINA_APP_E_PAYMENT_YOOMANEY_GET_TOKEN', (params) => {

            if(!apiDataByServers[store.getters.getServer]) {
                if(params.onError) {
                    params.onError(new Error("Отсутствуют данные для оплаты через SDK Юкассы."));
                }
                return;
            }

            let apiData = apiDataByServers[store.getters.getServer];

            yoomoney.getToken({ 
                userId: params.userId,
                orderId: params.orderId,
                price: params.price,
                key: apiData.key,
                shopId: apiData.shopId
            }).then((result) => {
                console.log(result);
                if(params.onSuccess) {
                    params.onSuccess(result.token,result.type);
                }
            }).catch((error) => {
                if(params.onError) {
                    params.onError(error);
                }
            });
        });

        //подтверждение оплаты 
        bus.on('OKRAINA_APP_E_PAYMENT_YOOMANEY_CONFIRM', (params) => {

            if(!apiDataByServers[store.getters.getServer]) {
                if(params.onError) {
                    params.onError(new Error("Отсутствуют данные для оплаты через SDK Юкассы."));
                }
                return;
            }

            let apiData = apiDataByServers[store.getters.getServer];

            yoomoney.confirm({ 
                confirmationUrl: params.confirmationUrl,
                type: params.type,
                key: apiData.key,
                shopId: apiData.shopId
            }).then(() => {
                if(params.onSuccess) {
                    params.onSuccess();
                }
            }).catch((error) => {
                if(params.onError) {
                    params.onError(error);
                }
            });
        });

        //отмена оплаты (ошибка оплаты)
        bus.on('OKRAINA_APP_E_PAYMENT_YOOMANEY_CANCEL', () => {
            yoomoney.cancel();
        });

        
    },

    /**
     * SDK SberPay
     */
    processSberPay() {

        let apiDataByServers = {
            "shop.okraina.ru": {
                apiKey: "ANFeySomZke9ub5+drK6AjkAAAAAAAAADLQBY4132JCUx6MD/LEIbL0qUd4OvCCy/G2kmj/ax0jUdU6DHuCR1C1+LfOL42KbrUuSW/GVRgqnBd+yQ/Qzq7sfdc8b6/4gUmILjIuP2/pl4y1ffWwftH0gHS891kaXg9SYlxOWeX+8NwXAN2EezyISzVsP6ZThP2qbo7Hyv2s2PtL+vUAbgEM1va2PJmBgrvY=",
                merchantLogin: "Okraina_SDK"
            },
        };

        //проверка доступности приложения
        bus.on('OKRAINA_APP_E_PAYMENT_SBERPAY_AVAILABLE', async (params) => {

            //проверяем, что плагин нативный имеется
            if (!Capacitor.isPluginAvailable('SberPay')) {
                if(params.callback) {
                    params.callback({
                        ready: false,
                        errorMessage: "Обновите приложение до последней версии."
                    });
                }
                return;
            }

            if (Capacitor.getPlatform() === 'android') {
                //просто запрашиваем некоторые права, чтобы SDK "лучше работал"
                //на результат не смотрим - в любом случае SDK отработает
                await sberpay.requestPermissions();
            }

            //проверяем доступно ли приложение Сбера
            sberpay.isReady().then((result) => {
                if(params.callback) {

                    params.callback({
                        ready: result.ready,
                        errorMessage: !result.ready? "Обновите приложение до последней версии." : ""
                    });
                }
            });

        });

        //проведение оплаты
        bus.on('OKRAINA_APP_E_PAYMENT_SBERPAY_PAY', (params) => {

            if(!apiDataByServers[store.getters.getServer]) {
                if(params.onError) {
                    params.onError(new Error("Отсутствуют данные для оплаты через SDK SberPay."));
                }
                return;
            }

            let apiData = apiDataByServers[store.getters.getServer];

            sberpay.payWithBankInvoiceId({
                apiKey: apiData.apiKey,
                merchantLogin: apiData.merchantLogin,
                bankInvoiceId: params.bankInvoiceId,
                orderNumber: params.orderNumber
            }).then((result) => {
                if(params.callback) {
                    params.callback(result);
                }
                
            }).catch((error) => {
                if(params.callback) {
                    params.callback({
                        status: "error",
                        errorMessage: error.message
                    });
                }
            });

        });
    },

    /**
     * Заглушка загрузки
     */
    processSplash() {
        bus.on('OKRAINA_APP_E_READY', () => {
            SplashScreen.hide().then(() => {
                //меняем цвет статус бара, а то сплеш скрин почему-то перезатирает
                setTimeout(() => {
                    bus.emit('OKRAINA_APP_E_THEME_CHANGED');
                },500)
                
            });
        });
    },

    /**
     * События клавиатуры
     */
    processKeybord() {

        if(!Capacitor.isPluginAvailable('Keyboard')) {
            return;
        }

        if(Capacitor.getPlatform() == "ios") {
            //отклочаем автоматическу прокрутку к полю ввода
            Keyboard.setScroll({isDisabled: true});
        }


        Keyboard.addListener('keyboardDidShow', info => {
        
            document.documentElement.classList.add("keyboard-show")
            document.documentElement.style.setProperty(`--keyboard-height`,`${info.keyboardHeight}px`);

            setTimeout(() => {
                let inputFocused = document.querySelector("input[type=text]:focus,input[type=password]:focus,textarea:focus");
                if (inputFocused) {
                    inputFocused.scrollIntoView({
                        behavior: "instant", 
                        block: "center", inline: "nearest"
                    });
                }
            },200);
            
            bus.emit('OKRAINA_KEYBOARD_E_DID_SHOW',info);
        });


        Keyboard.addListener('keyboardDidHide', () => {
            document.documentElement.classList.remove("keyboard-show")
            document.documentElement.style.setProperty(`--keyboard-height`,`0px`);
            bus.emit('OKRAINA_KEYBOARD_E_DID_HIDE');
            
        });

    },

    /**
     * События приложения
     */
    processApp() {

        //исключаем ios - там при длительном бездействии приложения при активации оно виснет и крешится
        if(Capacitor.getPlatform() != "ios") {

            //Пауза приложения
            App.addListener('pause',  () => {
                
                //сохраняем текущий адрес и данные компонентов
                let path = window.location.pathname+window.location.search;
            
                let restoreData = [];
                store.getters.getRestoreDataCallbacks.forEach((callback) => {
                    let data = callback();
                    if(data) {
                        restoreData.push(data);
                    }
                });
                let currentTime = Math.floor(Date.now() / 1000);

                storage.set("restore", {
                    path: path,
                    data: restoreData,
                    time: currentTime
                });

            });

            //возобнолвение с паузы
            App.addListener('resume',  () => {
                //если срабатывает - то приложение живое и данные на экранах ещё никуда не ушли
                //поэтому просто стираем сохранённые данные 
                storage.set("restore", "");

            });


            //получение данных плагина, который был вызван перед удалением нашего приложения из памяти
            App.addListener('appRestoredResult', (data) => {
                store.commit("setRestorePluginResult",data);
            });

            //подпишемся на готовность нашего приложения
            bus.on('OKRAINA_APP_E_READY', async () => {

                //специально немного ждём, чтобы если что успел сработать appUrlOpen
                setTimeout(async () => {

                    //получаем сохранённые данные для восстановления
                    let restore = await storage.get("restore");
                    let currentTime = Math.floor(Date.now() / 1000);
                    //восстанавливаем только если прошло не больше 3-х минут
                    if(!restore || !restore.path || parseInt(restore.time) + 3 *60 <  currentTime) {
                        return;
                    }
                    store.commit("setRestoreData",restore.data);

                    //сотрём сохранённые данные, чтобы в следующий старт приложения сюда же не восстановиться
                    storage.set("restore", "");

                    //парсим параметры (например переход к странице поиска)
                    const [, paramString] = restore.path.split('?');
                    let urlParams = new URLSearchParams(paramString);
                    let entries = urlParams.entries();
                    let query = {};
                    for (let [key, value] of entries) {
                        query[key] = value;
                    }

                    router.push({ path: restore.path, query: query }); 

                },500);

            });

        }

        //открытие приложения с определённым URL
        App.addListener('appUrlOpen',  (event) => {

            //если возврат из приложения Сбера
            if(event.url.startsWith("okraina://spay")) {
                //ничего не делаем, даём экрану оплаты восстановиться через restore
                return;
            }

            //убираем сохранённые данные экранов
            //так как вызов по ссылке не должен восстанавливать экраны
            storage.set("restore", "");

            //DeepLink для возврата из YouMoney 
            if(event.url.startsWith("okrainaapp://")) {
                let url = event.url.replace("okrainaapp:/","");

                //парсим параметры (например переход к странице поиска)
                const [, paramString] = url.split('?');
                let urlParams = new URLSearchParams(paramString);
                let entries = urlParams.entries();
                let query = {};
                for (let [key, value] of entries) {
                    query[key] = value;
                }
                
                router.push({path: url, query: query})
            } 
            //или обычная ссылка
            else if(event.url.startsWith("https://")){

                let url = new URL(event.url);
                if(store.getters.getServer != url.hostname) {
                    //вызываем смену региона
                    bus.emit("OKRAINA_APP_E_CHANGE_REGION", {
                        domain: url.hostname,
                        showFullScreenBaners: false
                    });
                }

                //парсим параметры (например переход к странице поиска)
                let entries = url.searchParams.entries();
                let query = {};
                for (let [key, value] of entries) {
                    query[key] = value;
                }

                //проверяем ссылку "Пригласить друга"
                let mathes = url.pathname.match(/affiliate\/([\d]*)\//);
                if(mathes && mathes.length == 2) {
                    //запоминаем данные аффилиата в сесии
                    store.dispatch("setSessionParam", { key: "B1T_OKRAINA_AFFILIATE", value: mathes[1]})
                }
                
                router.push({path: url.pathname, query: query})
            }
           
        });

        //клик по кнопке "Назад" на устройстве
        App.addListener('backButton', (data) => {

            //если в истории не пусто
            if (data.canGoBack) {
                //делаем возврат по истории, если не делать, то не будет вообще работать 
                //видимо обработчик backButton отключает стандартную логику возврата в истории при нажатии
                window.history.back();
                return;
            }

            //если не главный экран
            if (router.currentRoute.value.name != "home") {
                router.push({name: "home"});
            } else {

                //иначе спросим а нужно ли закрывать приложение
                bus.emit("OKRAINA_CONFIRM_E_SHOW", {
                    message: "Закрыть приложение?",
                    resolveText: "Да",
                    rejectText: "Нет",
                    resolve: function (value) {
                        if(value) {
                            App.exitApp();
                        }
                    },
                    reverse: false,
                });
                
            }
        });


    },

    /**
     * Работа с буфером
     */
    processClipboard() {

        if(!Capacitor.isPluginAvailable('Clipboard')) {
            return;
        }

        bus.on('OKRAINA_CLIPBOARD_E_WRITE', async (params) => {

            if(store.getters.getLaunchPlatform == "vk") {

                vkBridge.send('VKWebAppCopyText', {
                    text: params.text
                })
                .then((data) => { 
                    if (data.result) {
                        bus.emit("OKRAINA_MESSAGE_E_SHOW", { message: params.successMessage, type: "success" });
                    } else {
                        // Ошибка
                    }
                })
                .catch((error) => {
                    // Ошибка
                });
                
            } else {

                Clipboard.write({
                    string: params.text
                }).then( () => {
                    bus.emit("OKRAINA_MESSAGE_E_SHOW", { message: params.successMessage, type: "success" });
                });
                
            }

        });
    },

    /**
     * Сервисы GSM / HMS (Пуш, Гео и пр)
     */
    processMobileServices() {

        //если установлен плагин проверки доступности сервисов Google
        if(Capacitor.getPlatform() === 'android' && Capacitor.isPluginAvailable('CheckPlayServices')) {

            CheckPlayServices.isAvailable().then(result => {
                
                //если сервисы Гугл доступны
                if(result.available) {
                    //локальные уведомления уведомлений
                    googleLocalNotifications.init();
                    //push
                    googlePush.init();
                    //местоположение
                    googleGeo.init();
                } 
                //иначе это скорее вcего Huawei 
                else {

                    //хак если есть объект push HMS (есть Honor, у которых доступен Google Play, соответственно не установлен HMS)
                    if(window.HmsPushEvent) {
                        //локальные уведомления уведомлений
                        hmsLocalNotifications.init();
                        //push
                        hmsPush.init();
                    } 
                }
            })


        }
        //обратная совместимость (старый код)
        else {

            //хак если есть объект push HMS (есть Honor, у которых доступен Google Play, соответственно не установлен HMS)
            if(window.HmsPushEvent) {
                //локальные уведомления уведомлений
                hmsLocalNotifications.init();
                //push
                hmsPush.init();
            } else {
                //локальные уведомления уведомлений
                googleLocalNotifications.init();
                //push
                googlePush.init();
                //местоположение
                googleGeo.init();
            }
        }

    },

}